.navbar-brand:hover {
  opacity: 0.7;
}

.image-preview {
  object-fit: cover;
  width: 100%;
  height: 150px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.image-preview:hover {
  opacity: 0.8;
}

.toast-info {
  position: fixed;
  top: 40px;
  right: 20px;
}

.link-active {
  font-weight: 700;
  color: rgba(0, 0, 0, 1) !important;
}

.full-screen {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.8);
}

.full-screen i {
  font-size: 30px;
  color: #ccc;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.full-screen img {
  max-width: 90vw;
  max-height: 90vh;
}

.full-screen i:hover {
  color: #fff;
}

.full-screen.visible {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
